/* Coustom
@media only screen and (max-width: 991px) {
  .sabuj_ml_tb_2 {
    margin-left: 0.5rem !important;
  }
  body {
    background: green;
  }
} */
img{
  object-fit: cover;
}
.pointer {
  cursor: pointer;
}
.search-input {
  padding-bottom: 26px !important;
  padding-top: 26px !important;
  font-size: 14px !important;
}
.rounded-border {
  border-radius: 8px;
}
.card-style {
  border-radius: 15px;
  border: 2px solid #edeff7;
  background: linear-gradient(90deg, #0a234c 17.43%, #27496d 79.14%);
  box-shadow: 0px 8px 4px 0px rgba(0, 0, 0, 0.25);
}
.text-color {
  color: #00909e;
}
.theme-btn {
  border-radius: 3px;
  background: #00909e;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #142850;
  font-size: 14px;
  font-weight: 700;
}
.theme-btn:hover {
  border-radius: 3px;
  background: #00909e;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #142850;
  font-size: 14px;
  font-weight: 700;
}
.filter-border {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}
.grid-fit-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  gap: 2rem;
}
.css-yk16xz-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-left: 10px !important;
  border: 1px solid #f0f1f5 !important;
  border-radius: 12px !important;
}
.css-1pahdxg-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  border-color: #2684ff !important;
  border-radius: 12px !important;
  box-shadow: 0 0 0 0 #2684ff !important;
}
.custom-audio{
  height: 120px;
  width: 240px;
  border-radius: 15px;
}
.custom-audio audio{
  height: 34px;
  width: 240px;
}
.custom-video{
  width: 380px;
  height: 200px;
}
.custom-video video{
object-fit: fill;
}
.header-dropdown {
  color: "#7e7e7e";
  padding: 15px;
  border-color: lightgrey;
  border-radius: 6px;
  font-size: 14px;
  border-color: #eeeeee;
  appearance: none;
  background-image: url(./images/CaretDown.svg);
  background-repeat: no-repeat;
  background-size: 0.75em auto, 100%;
  background-position: right 0.6em top 50%, 0 0;
}
.down-arrow {
  padding-right: 30px;
  background-position: right 0.9em top 50%, 0 0;
}
