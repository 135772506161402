@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

.card{
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2) !important;
    
}
.support-img-card {
  display: flex !important;
  flex-direction: column !important;
  width: 440px !important;
  height: auto;
  background-color: #fff !important;
  border-radius: 10px !important;
  box-shadow: 0 10px 20px 0 rgba(#999, 0.25) !important; 
  padding: 1.5rem !important;
}
.support-card-img {
  height: 200px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  text-align: center;
  background: linear-gradient(90deg, #0a234c 17.43%, #27496d 79.14%);
}
.cards-img-logo {
  object-fit: cover;
  height: 200px;
  width: 150px;
}
.support-card-form {
  padding: 2rem 1rem 0;
}
.support-input
{
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  padding-top: 1.5rem;

  margin-top: 1.5rem;
}
.support-input-label
{
  color: #8597a3;
  position: absolute;
  top: 1.5rem;
  transition: 0.25s ease;
}
.support-input-field
 {
  border: 0;
  z-index: 1;
  background-color: transparent;
  border-bottom: 2px solid #eee;
  font: inherit;
  font-size: 1.125rem;
  padding: 0.25rem 0;
}
.support-input-field:focus {
  outline: 0;
  border-bottom-color: #00909e;
}
.support-input-field:valid {
  outline: 0;
  border-bottom-color: #00909e;
}
.support-input-label
{
  color: #00909e;
  transform: translateY(-1.5rem);
}
.support-action {
  margin-top: 2rem;
}
.support-action-button {
  font: inherit;
  font-size: 1.1rem;
  width: 100%;
  font-weight: 500;
  background-color: #00909e;
  border-radius: 6px;
  color: #fff;
  border: 0;
  padding: 10px;
}
.support-action-button:focus {
  outline: 0;
}

